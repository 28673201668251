export default {
  primary: '#1F9799',
  lightprimary: 'rgba(0, 155, 166, 0.12)',
  transparencyPrimary: 'rgba(0, 155, 166, 0.18)',
  white: '#FFF',
  black: '#191919',
  grey: 'rgb(246,246,246)',
  secondaryGrey: 'rgb(137,137,137)',
  // placeholder: 'rgb(214,214,214)',
  subtitle: 'rgb(33,33,33)',
  subtitle2: 'rgb(64,64,64)',
  greyOutTitle: 'rgb(100,100,100)',
  shadow: 'rgba(21,114,115,0.5)',
  gradient1: '#6cd3ac',
  gradient2: '#41b3a2',
  inputLabel: 'rgb(124,124,124)',
  info: '#EFEDFB',
  info2: '#E9F5F8',
  info3: '#ECEBEC',
  info4: '#DADADA',
  error: 'red',
  border: 'rgb(215,215,215)',
  divider: '#EDEDED',
  alert: '#D72929',
  lightalert: 'rgba(255, 0, 0, 0.10)',
  warmGrey: '#727272',
  orange: '#eb5e00',
  orangeLight: '#FF7A01',
  lightorange: 'rgba(255, 122, 0, 0.06)',
  transparencyOrange: 'rgba(235, 94, 0, 0.18)',
  orangeBorder: '#f0753e',
  secondaryText: '#212121',
  orangeMedium: '#ff7847',
  sideTabBackgroundColor: '#dadada',
  sideTabColor: '#4D4D4D',
  toolsBarBackgroundColor: '#E6E6E6',
  toolsBackgroundColorHover: '#090909',
  backgroundGrey: '#f4f4f4',
  dropdownItem: '#2f2f2f',
  dropdownDivider: '#212121',
  placeholder: '#939393',
  cancel: '#EE7852',
  drawerHeaders: '#727272',
  sideTabCountColor: '#4c4c4c',
  greyButton: '#3f3f3f',
  inputBorder: '#aeaeae',
  blocked: '#e1e1e1',
  lightGrey: '#f5f5f5',
  lightGrey2: '#eeeeee',
  lightGrey3: '#f8f8f8',
  lightGrey4: '#f7f7f7'
} as const;
